/* eslint-disable camelcase */
import { defineStore } from 'pinia';

import { useNuxtStore } from './nuxt';

export const useDomainStore = defineStore('domain', {
  state: () => ({
    domains: null,
    mappedDomains: {
      hk: {
        // field_key: domain_key
        loanPurpose: 'loanPurpose',
        loanProduct: 'loanProduct',
        gender: 'genders',
        residency: 'residencies',
        workStatus: 'employments',
        district: 'districts',
        employerDistrict: 'districts',
        residentialStatus: 'residentialStatuses',
        residentialPropertyType: 'residentialPropertyTypes',
        eduLevel: 'educationLevels',
        civilStatus: 'civilStatuses',
        jobIndustry: 'jobIndustries',
        currentEmploymentDuration: 'employmentDuration',
        payrollType: 'payrollTypes',
        mpfContribution: 'yesNo',
        lateWithRepayment: 'lateWithRepayment',
        loanWriteOffStatus: 'loanWriteOffStatuses',
        bankruptcyStatus: 'bankruptcyStatuses',
        appliedLoanRecently: 'appliedLoanRecentlyStatuses',
        totalOutstandingDebt: 'outstandingDebt',
        lengthOfStay: 'lengthOfStay',
        assetType: 'assetTypes',
        maritalStatus: 'maritalStatus',
        educationLevel: 'educationLevel',
        mpf: 'mpf',
        easylendJobTitle: 'easylendJobTitle',
        occupation: 'occupation',
        addressLine: 'addressLine',
        carMake: 'carMakes',
        nationality: 'nationalities',
        ownsCar: 'yesNoOptions',
      },
      sg: {
        residency: 'residencies',
        workStatus: 'employments',
        loanPurpose: 'loanPurpose',
        civilStatus: 'civilStatuses',
        housingType: 'housingTypePPOptions',
        housingStatus: 'housingStatuses',
        jobTitle: 'jobTitles',
        lengthOfStay: 'lengthOfStay',
        jobIndustry: 'jobIndustries',
        currentEmploymentDuration: 'employmentDurations',
        previousEmploymentDuration: 'prevEmploymentDurations',
        ownsProperty: 'propertyOwnershipStatuses',
        PEPStatus: 'yesNo',
      },
      au: {
        residency: 'residencies',
        workStatus: 'employments',
        loanPurpose: 'loanPurpose',
        civilStatus: 'civilStatuses',
        housingType: 'housingTypes',
        housingStatus: 'housingStatuses',
        jobTitle: 'jobTitles',
        lengthOfStay: 'lengthOfStay',
        jobIndustry: 'jobIndustries',
        currentEmploymentDuration: 'employmentDurations',
        previousEmploymentDuration: 'prevEmploymentDurations',
        ownsProperty: 'yesNo',
        PEPStatus: 'yesNo',
      }
    },
  }),

  actions: {
    getDomains () {
      const nuxtStore = useNuxtStore();
      const language = nuxtStore.language;
      return (language) ? (this.domains[language].optionsSets || {}) : {};
    },
    getMappedDomains () {
      const nuxtStore = useNuxtStore();
      const locale = nuxtStore.locale;
      return (locale) ? (this.mappedDomains[locale] || {}) : {};
    },
    contentText () {
      const nuxtStore = useNuxtStore();
      const language = nuxtStore.language;
      return (language) ? (this.domains[language].applications.appFields || {}) : {};
    },
    tabLabels () {
      const nuxtStore = useNuxtStore();
      const language = nuxtStore.language;
      return (language) ? (this.domains[language].applications.tabLabels || {}) : {};
    },
    miscButtonLabels () {
      const nuxtStore = useNuxtStore();
      const language = nuxtStore.language;
      return (language) ? (this.domains[language].applications.miscButtonLabels || {}) : {};
    },
    languageLabels () {
      const nuxtStore = useNuxtStore();
      const language = nuxtStore.language;
      return (language) ? (this.domains[language].applications.languageLabels || {}) : {};
    },
    miscTexts () {
      const nuxtStore = useNuxtStore();
      const language = nuxtStore.language;
      return (language) ? (this.domains[language].applications.miscTexts || {}) : {};
    },
    offerFieldsText () {
      const nuxtStore = useNuxtStore();
      const language = nuxtStore.language;
      return (language) ? (this.domains[language].applications.offerFields || {}) : {};
    },
    prefixes () {
      const nuxtStore = useNuxtStore();
      const language = nuxtStore.language;
      return (language) ? (this.domains[language].applications.prefixes || {}) : {};
    },
    appointmentsText () {
      const nuxtStore = useNuxtStore();
      const language = nuxtStore.language;
      return (language) ? (this.domains[language].applications.appointmentsDrawer || {}) : {};
    },
    performanceText () {
      const nuxtStore = useNuxtStore();
      const language = nuxtStore.language;
      return (language) ? (this.domains[language].applications.newPerformanceDrawer || {}) : {};
    },
    fieldsListText () {
      const nuxtStore = useNuxtStore();
      const language = nuxtStore.language;
      return (language) ? (this.domains[language].applications.fieldsListDrawer || {}) : {};
    },
    appointmentMissedText () {
      const nuxtStore = useNuxtStore();
      const language = nuxtStore.language;
      return (language) ? (this.domains[language].applications.appointmentMissedPopup  || {}) : {};
    },
    portalUsers () {
      return this.domains.portalUsers || [];
    },
    settingTexts () {
      const nuxtStore = useNuxtStore();
      const language = nuxtStore.language;
      const { general_texts, settingSections, settingFieldsLabels: fieldLabels, settingFieldsDescription } = this.domains[language].settings;
      const settingFieldsText = {};

      Object.keys(fieldLabels).forEach((field) => {
        settingFieldsText[field] = {
          label: fieldLabels[field].label,
          description: settingFieldsDescription[field].label
        };
      });

      const returnTexts = {
        general: general_texts || {},
        sectionLabels: settingSections || {},
        fieldTexts: settingFieldsText || {},
      };
      return returnTexts;
    },
    searchbarTexts () {
      const nuxtStore = useNuxtStore();
      const language = nuxtStore.language;
      return (language) ? (this.domains[language].searchbar  || {}) : {};
    },
    rejectionReasons () {
      const nuxtStore = useNuxtStore();
      const language = nuxtStore.language;
      return (language) ? (this.domains[language].applications.rejectionReasons || {}) : {};
    },
    rejectionCategories () {
      const nuxtStore = useNuxtStore();
      const language = nuxtStore.language;
      return (language) ? (this.domains[language].applications.rejectionCategories || {}) : {};
    },
    rejectionSubCategories () {
      const nuxtStore = useNuxtStore();
      const language = nuxtStore.language;
      return (language) ? (this.domains[language].applications.rejectionSubCategories || {}) : {};
    },
    rejectionSubCategoriesMasterList () {
      const nuxtStore = useNuxtStore();
      const language = nuxtStore.language;
      return (language) ? (this.domains[language].applications.rejectionSubCategoriesMasterList || {}) : {};
    },
    productTexts () {
      const nuxtStore = useNuxtStore();
      const language = nuxtStore.language;
      const products = this.domains[language].products || [];
      const productTexts = {};
      products.map((p) => { productTexts[p.value] = p.text });
      return productTexts;
    },
    products () {
      const nuxtStore = useNuxtStore();
      const language = nuxtStore.language;
      return this.domains[language].products || [];
    },
    appointmentDocuments () {
      const nuxtStore = useNuxtStore();
      const language = nuxtStore.language;
      return (language) ? (this.domains[language].appointmentDocuments || []) : [];
    },
    uploadDocuments () {
      const nuxtStore = useNuxtStore();
      const language = nuxtStore.language;
      return (language) ? (this.domains[language].uploadDocuments || []) : [];
    },
    addressesList () {
      const nuxtStore = useNuxtStore();
      const language = nuxtStore.language;
      return (language) ? (this.domains[language].addressesList || {}) : {};
    },
    setDomains (domains) {
      // this.domains = toRaw({ ...this.domains, ...domains });
      this.domains = domains;
    }
  }
});
